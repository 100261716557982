import styled from 'styled-components';

export const GlobalError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 66px 32px 0;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 448px;
`;

export const ErrorImage = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;

  @media screen and (max-width: 600px) {
    max-width: 200px;
  }
`;

export const ErrorTitle = styled.h2`
  margin-top: 20px;
`;

export const ErrorText = styled.p`
  margin: 32px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #344152;
`;
