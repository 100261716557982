import styled, { keyframes } from 'styled-components';

const skBouncedelay = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const Spinner = styled.div`
  width: 50px;
  text-align: center;

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
`;

export const Bounce = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 3px;
  background-color: ${props => props.theme.colors.gray};

  border-radius: 100%;
  display: inline-block;
  animation: ${skBouncedelay} 1.4s infinite ease-in-out both;
`;
