import styled from 'styled-components';

export const PhoneNumberInput = styled.div`
  max-width: ${props => props.theme.form.inputMaxWidth};

.PhoneInputCountryIcon {
    height: auto;
    width: 35px;
    box-shadow: none;
  }

  input {
    min-width: 250px;
    height: ${props => props.theme.form.inputHeight};
    width: 100%;
    max-width: ${props => props.theme.form.inputMaxWidth};
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.hasError ? props.theme.colors.error : props.theme.form.inputBorder)};
    outline: none;
    padding: 0 10px;
    color: ${props => props.theme.colors.textPrimary};
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    border-radius: ${props => props.theme.borderRadius};

    @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
      min-width: 200px;
    }

    &:disabled {
      background-color: ${props => props.theme.form.inputDisabledBackground};

      &:hover {
        border-color: ${props => props.theme.form.inputDisabledBorder};
      }
    }

    &:focus,
    &:hover {
      border-color: ${props => props.theme.form.inputBorderFocus};
    }
    }
`;
