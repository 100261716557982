import styled from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const Image = styled.img`
  width: ${props => (props.isID ? '250px' : '150px')};
  height: 150px;
  object-fit: cover;
  border: 1px solid #eaeaea;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 5px;
`;

export const ImageDate = styled.div`
  font-size: 13px;
`;
