import styled from 'styled-components';

export const UsersList = styled.ul`
  padding-inline-start: 0;
  list-style-type: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 3px 6px 0 rgba(210, 222, 235, 0.4);
  background-color: #fff;
  max-width: 1440px;
`;

export const ListItemData = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xxs}) {
    max-width: 250px;
  }
`;

export const ListItemUsername = styled.div`
  margin-bottom: 5px;
  margin-right: 30px;
  word-break: break-word;
`;

export const ListItemLabel = styled.div`
  font-size: 13px;
  margin-bottom: 2px;
  color: ${props => props.theme.colors.textSecondary};
`;

export const ListItemValue = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

export const ListItemAction = styled.div`
  margin-left: auto;

  a {
    padding: 10px;
    color: ${props => props.theme.colors.blue}
  }
`;
