export const Theme = {
  colors: {
    background: '#F5F8FA',
    headerBackground: '#22BE84',
    textPrimary: '#344152',
    textSecondary: '#717883',
    blue: '#1270D3',
    green: '#006F6C',
    red: '#F13535',
    success: '#40CE63',
    error: '#EC3C3C',
    gray: '#57677C',
  },
  dimensions: {
    headerHeight: '50px',
  },
  breakpoints: {
    xxxs: '320px',
    xxs: '400px',
    xs: '600px',
    s: '768px',
    m: '1024px',
    l: '1366px',
    xl: '1920px',
    xxl: '2560px',
  },
  form: {
    inputBorder: '#E3E3E3',
    inputBorderFocus: '#22BE84',
    inputMaxWidth: '300px',
    inputHeight: '40px',
    inputDisabledBackground: '#D0D8E5',
    inputDisabledBorder: '#D0D8E5',
  },
  borderRadius: '3px',
};
