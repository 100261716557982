// Fetching user
export const getUserInit = 'GET_USER_INIT';
export const getUserSuccess = 'GET_USER_SUCCESS';
export const getUserError = 'GET_USER_ERROR';
export const refreshUser = 'REFRESH_USER';

// Login
export const loginInit = 'LOGIN_INIT';
export const loginSuccess = 'LOGIN_SUCCESS';
export const loginError = 'LOGIN_ERROR';

// Logout
export const logoutInit = 'LOGOUT_INIT';
export const logoutSuccess = 'LOGOUT_SUCCESS';
export const logoutError = 'LOGOUT_ERROR';

// Register
export const registerInit = 'REGISTER_INIT';
export const registerSuccess = 'REGISTER_SUCCESS';
export const registerError = 'REGISTER_ERROR';
