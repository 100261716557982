import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 14px;
`;

export const LabelElem = styled.label`
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  margin-right: 5px;
  text-transform: ${props => (props.upperCase ? 'uppercase' : 'initial')}
`;
