import axios from 'axios';

axios.defaults.withCredentials = true;

const isDevelopment = process.env.NODE_ENV === 'development';
const endpoint = '';

const buildDevUrl = (url) => {
  const port3050 = [
    '/api/user-management/auth',
    '/api/user-management/register',
    '/api/user-management/change-password',
    '/api/user-management/roles',
    '/api/user-management/user-profiles',
    '/api/user-management/users',
    '/api/user-management/files',
    '/api/user-management/tickets',
    '/api/user-management/countries',
  ];
  const port3051 = [
    'api/yoc/invitations',
    'api/yoc/topologies',
    'api/yoc/topology-types',
    'api/yoc/subtopologies',
  ];
  const port3052 = [
    'api/kyc/upload-file',
    'api/kyc/compare-faces',
    '/api/kyc/extract-text',
  ];
  let devEndpoint;

  port3050.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3050${url}`; }
  });

  port3051.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3051${url}`; }
  });

  port3052.forEach(i => {
    if (url.includes(i)) { devEndpoint = `http://localhost:3052${url}`; }
  });
  return devEndpoint;
};

const getPath = url => {
  if (isDevelopment) {
    const devURL = buildDevUrl(url);
    return devURL;
  }
  return `${endpoint}${url}`;
};

const api = {
  get: (url, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) => axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  delete: (url, data = undefined, config = undefined) => axios.delete(getPath(url), data, config), // { data: { params to be sent here } }
};

export default api;
