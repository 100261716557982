import styled from 'styled-components';

export const UserFiles = styled.div`
  margin-top: 20px;
`;

export const UserFilesEmpty = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.textSecondary};
  margin-top: 3px;
`;

export const UserFilesHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const UserFilesLabel = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
`;

export const UserFilesValue = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
