/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

const getSM = () => css`
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  padding: 0 20px;

  .ContentLoader-spinner {
    width: 10px;
    height: 10px;
    border-width: 1px;
  }
`;

const getMD = (props) => css`
  font-size: 13px;
  font-weight: 600;
  height: ${() => props.theme.form.inputHeight};
  padding: 0 30px;

  .ContentLoader-spinner {
    width: 13px;
    height: 13px;
    border-width: 1px;
  }
`;

const getLG = () => css`
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  padding: 0 40px;

  .ContentLoader-spinner {
    width: 15px;
    height: 15px;
    border-width: 1px;
  }
`;

const sizeStyling = (props) => {
  const { size } = props;

  switch (size) {
    case 'sm':
      return getSM();
    case 'md':
      return getMD(props);
    case 'lg':
      return getLG();
    default:
      return getMD();
  }
};

const getInfoVariation = (props) => css`
  background: ${() => props.theme.colors.blue};
`;

const getSuccessVariation = (props) => css`
  background: ${() => props.theme.colors.green};
`;

const getErrorVariation = (props) => css`
  background: ${() => props.theme.colors.red};
`;

const getLinkVariation = (props) => css`
  background: transparent;
  border: none;
  color: ${() => props.theme.colors.textPrimary};
  box-shadow: none;

  .ContentLoader-spinner {
    border-top-color: ${() => props.theme.colors.textPrimary} !important;
  }
`;

const variationStyling = (props) => {
  const { variation } = props;

  switch (variation) {
    case 'default':
      return getInfoVariation(props);
    case 'success':
      return getSuccessVariation(props);
    case 'error':
      return getErrorVariation(props);
    case 'link':
      return getLinkVariation(props);
    default:
      return getInfoVariation(props);
  }
};

const getWideStyling = () => css`
  width: 100%;
  max-width: 400px;
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid transparent;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  outline: 0;
  cursor: pointer;
  transition: 150ms all ease-in-out;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  margin: 10px 0;
  color: #fff;
  border-radius: ${props => props.theme.borderRadius};

  .ContentLoader {
    padding: 0;

    .ContentLoader-spinner {
      margin-right: 10px;
      border-top-color: #fff;
    }

    .ContentLoader-text {
      display: none;
    }
  }

  ${props => sizeStyling(props)}
  ${props => variationStyling(props)}
  ${props => {
    if (props.wide) {
      return getWideStyling();
    }
  }}

  &:hover,
    &:focus {
      opacity: 0.9;
    }

  &:disabled {
    background-color: ${props => props.theme.form.inputDisabledBackground};
    border: ${props => props.theme.form.inputDisabledBackground};
    color: ${props => props.theme.colors.textPrimary};
    cursor: not-allowed;

    &:hover {
      opacity: 0.8;
    }
  }
`;
