import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const ContentLoader = styled.div`
  padding: 50px 0;
  text-align: center;
`;

export const LoaderSpinner = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: ${props => props.color};
  animation: ${spin} 750ms linear infinite;
`;

export const LoaderText = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;
