import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.colors.background};
`;

export const FormContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.15);
  padding: 20px 50px;
  position: relative;
  border-radius: ${props => props.theme.borderRadius};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 20px 0;
    width: 100%;
  }

  fieldset {
    border: 0;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;

  .TextInput,
  .PasswordInput {
    max-width: unset;
    width: 100%;

    input {
      max-width: unset;
      width: 100%;
    }
  }
`;

export const FormHeading = styled(FormRow)`

  h1 {
    font-size: 18px;
    margin: 10px 0 20px;
    text-align: center;
    color: ${props => props.theme.colors.green};
    text-transform: uppercase;
  }
`;
