import styled from 'styled-components';

export const Row = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
