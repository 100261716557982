import styled from 'styled-components';

export const NotAuth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 96px 32px 0;
`;

export const NotAuthImage = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
`;

export const NotAuthText = styled.p`
  margin: 32px 0;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: ${props => props.theme.colors.textPrimary};
`;
