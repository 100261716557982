import PropTypes from 'prop-types';
import { ContentLoader } from 'shared/components';
import * as Styled from './styles';

const Button = ({
  children,
  disabled,
  handleClick,
  size,
  variation,
  loading,
  wide,
  ...rest
}) => (
  <Styled.Button
    size={size}
    variation={variation}
    wide={wide}
    type="button"
    disabled={disabled}
    onClick={handleClick}
    {...rest}
  >
    <>
      { loading && <ContentLoader />}
      {children}
    </>
  </Styled.Button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variation: PropTypes.oneOf(['default', 'success', 'error', 'link']),
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  wide: false,
  handleClick: null,
  size: 'md',
  variation: 'default',
};

export default Button;
