import styled, { css } from 'styled-components';

const getPropsStyles = (props) => css`
  color: ${() => {
    if (props.disabled) {
      return props.theme.form.inputDisabledBackground;
    }
    if (props.hasError) {
      return props.theme.colors.error;
    }
    return props.theme.colors.textPrimary;
  }};
`;

export const RadioBtn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const RadioBtnLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;

  span {
    display: flex;
    ${props => getPropsStyles(props)}
  }
`;

export const RadioBtnInput = styled.input`
  margin-right: 10px;
  margin-left: 0;
  margin-top: 0;
  
  ${props => getPropsStyles(props)}
`;
