import { useTranslation } from 'react-i18next';
import icon from './bug_fixing.svg';
import * as Styled from './styles';

const GlobalError = () => {
  const [t] = useTranslation();

  return (
    <Styled.GlobalError>
      <Styled.ErrorWrapper>
        <Styled.ErrorImage src={icon} alt="Error message" />
        <Styled.ErrorTitle>{t('Error occured')}</Styled.ErrorTitle>
        <Styled.ErrorText>
          {t('Our tech team has been notified, to restart browsing please click on a button below')}
        </Styled.ErrorText>
        <button
          onClick={() => {
            window.location = '/';
          }}
          type="button"
        >
          {t('Restart')}
        </button>
      </Styled.ErrorWrapper>
    </Styled.GlobalError>
  );
};

export default GlobalError;
