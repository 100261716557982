import styled from 'styled-components';

export const RadioBtnsWrapper = styled.div`
  display: flex;

  .RadioBtn {
    
    &:first-of-type {
      margin-right: 40px;
    }
  }
`;

export const InputWrapper = styled.div`
  margin-top: 15px;
`;
