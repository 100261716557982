import styled from 'styled-components';

export const SidebarMenu = styled.aside`
  height: 100%;
  padding: 0 0 20px;
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.textPrimary};
  outline: none !important;
  display: flex !important;
  flex-direction: column;
`;

export const MenuHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px;
`;

export const MenuList = styled.nav`
  max-height: 70vh;
  overflow-y: auto;
`;

export const MenuListItem = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  outline: none;
  background-color: ${props => {
    if (props.active) {
      return '#dcdcdc';
    }
    return 'transparent';
  }};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    flex-shrink: 0;
    margin-right: 10px;
    width: 30px;
  }
`;

export const ItemText = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const MenuFooter = styled.div`
  margin-top: auto;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const MenuVersion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;
