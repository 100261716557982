import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import LoginContainer from 'login/LoginContainer';
import AppContainer from 'AppContainer';
import { ContentLoader } from 'shared/components';
import { getUser } from 'redux/account/actions';
import { Auth } from 'shared/HOCs';
import { Theme } from './Theme';

const App = () => {
  const dispatch = useDispatch();
  const userLoading = useSelector(state => get(state, 'account.userLoading'));

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  if (userLoading) {
    return <ContentLoader />;
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={LoginContainer} />
            <Route path="/" component={Auth(AppContainer)} />
          </Switch>
        </BrowserRouter>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          toastOptions={{
            duration: 1500,
            style: {
              borderRadius: 2,
              fontSize: '13px',
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default App;
