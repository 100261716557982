import styled from 'styled-components';

export const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 96px 32px 0;
`;

export const NotFoundImage = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 200px;
  }
`;

export const NotFoundText = styled.p`
  margin: 32px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.colors.textPrimary};
`;
