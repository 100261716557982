import PropTypes from 'prop-types';
import * as Styled from './styles';

const ContentLoader = ({ text, color }) => (
  <Styled.ContentLoader className="ContentLoader">
    <Styled.LoaderSpinner color={color} className="ContentLoader-spinner" />
    {text && <Styled.LoaderText>{text}</Styled.LoaderText>}
  </Styled.ContentLoader>
);

ContentLoader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

ContentLoader.defaultProps = {
  color: '#57677C',
  text: '',
};

export default ContentLoader;
