import styled from 'styled-components';

export const Selector = styled.div`
  max-width: ${props => props.theme.form.inputMaxWidth};

  .react-dropdown-select {
    max-width: ${props => props.theme.form.inputMaxWidth};
    min-height: 32px;
  }

  .Select {
    border-radius: ${props => props.theme.borderRadius};
    background: #fff;
    font-size: 14px;
    height: ${props => props.theme.form.inputHeight};

    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      box-shadow: none;
      border: none;
    }

    &:focus,
    &:focus-within {
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.form.inputBorderFocus}
    }

    &:hover {
      border-width: 1px;
      border-style: solid;
      border-color: ${props => props.theme.form.inputBorderFocus}
    }

    &:disabled {
      background: ${props => props.theme.form.inputDisabledBackground}
    }

    .react-dropdown-select-content {
      max-width: ${props => props.theme.form.inputMaxWidth};
      background: #fff;
    }

    .react-dropdown-select-type-multi {

      .react-dropdown-select-option {
        background: ${props => props.theme.colors.blue};
        border-radius: ${props => props.theme.borderRadius};
      }
    } 

    .react-dropdown-select-dropdown {
      max-width: ${props => props.theme.form.inputMaxWidth};
      width: 100%;
      border: none;
      border-radius: ${props => props.theme.borderRadius};
      box-shadow: 0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.15);
      background: #fff;

      .react-dropdown-select-item {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 13px;

        &:hover {
          background-color: ${props => props.theme.form.inputDisabledBackground}
        }

        &.react-dropdown-select-item-selected {
          background-color: ${props => props.theme.colors.blue};
        }
      }
    }
  }
`;
