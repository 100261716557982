import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import { InputErrorMessage } from 'shared/components';
import 'react-phone-number-input/style.css';
import * as Styled from './styles';

const PhoneNumberInput = ({
  error,
  placeholder,
  value,
  onChange,
  ...rest
}) => (
  <Styled.PhoneNumberInput>
    <PhoneInput
      placeholder=""
      value={value}
      onChange={onChange}
      {...rest}
    />
    {error && <InputErrorMessage text={error} />}
  </Styled.PhoneNumberInput>
);

PhoneNumberInput.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

PhoneNumberInput.defaultProps = {
  error: '',
  placeholder: 'Enter phone number',
};

export default PhoneNumberInput;
