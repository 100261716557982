import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Label,
  Modal,
  PhoneNumberInput,
  RadioBtn,
  Selector,
  TextInput,
} from 'shared/components';
import { emailRegex } from 'shared/constants';
import * as Styled from './styles';

const invitationTypes = {
  number: 'number',
  email: 'email',
};

const roleOptions = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Super-user',
    value: 'super_user',
  },
];

const InviteUserForm = ({
  isOpen,
  closeCb,
}) => {
  const [t] = useTranslation();
  const user = useSelector(state => get(state, 'account.user'));
  const userName = get(user, 'user_name');

  const [selectedType, setSelectedType] = useState(invitationTypes.number);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState([roleOptions[0]]);

  const isEmailValid = () => {
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setEmailError('Enter valid email');
    }
    return isValid;
  };

  const isPhoneNumberValid = () => {
    if (!phoneNumber) {
      setPhoneNumberError('This field is required');
      return false;
    }
    return true;
  };

  const isFormValid = () => {
    const isEmailSelected = selectedType === invitationTypes.email;
    if (isEmailSelected) {
      return isEmailValid();
    }
    return isPhoneNumberValid();
  };

  const handleSelectedTypeChange = (val) => {
    setPhoneNumber('');
    setEmail('');
    setEmailError('');
    setPhoneNumberError('');
    setSelectedType(val);
  };

  const handleSubmit = () => {
    if (loading) {
      return false;
    }

    const isValid = isFormValid();
    if (!isValid) {
      return false;
    }

    setLoading(true);

    const inviteeData = selectedType === invitationTypes.email ? email : phoneNumber;
    const data = {
      inviter: userName,
      invitee: inviteeData,
      topo_type: null,
      role_name: get(selectedRole, '[0].value') || 'user',
      app_name: 'client',
    };

    api.post('/api/yoc/invitations', data)
      .then(() => {
        toast.success(`Invitation sent to ${inviteeData}`);
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={loading}
      title="Invite user"
      size="sm"
    >
      <div className="InviteUserForm">
        <div className="type-selection">
          <Label text={t('Type')} inputId="type" />
          <Styled.RadioBtnsWrapper>
            <RadioBtn
              name="invitation-type-select"
              inputId="invitation-type-num"
              label={t('Phone number')}
              value={invitationTypes.number}
              checked={selectedType === invitationTypes.number}
              handleChange={handleSelectedTypeChange}
            />
            <RadioBtn
              name="invitation-type-select"
              inputId="invitation-type-email"
              label={t('Email')}
              value={invitationTypes.email}
              checked={selectedType === invitationTypes.email}
              handleChange={handleSelectedTypeChange}
            />
          </Styled.RadioBtnsWrapper>
        </div>
        <Styled.InputWrapper>
          {selectedType === invitationTypes.number && (
            <PhoneNumberInput
              value={phoneNumber}
              onChange={val => {
                setPhoneNumber(val);
                setPhoneNumberError('');
              }}
              error={phoneNumberError}
            />
          )}
          {selectedType === invitationTypes.email && (
            <div className="email-container">
              <TextInput
                name="email"
                id="email"
                type="email"
                autoComplete
                error={emailError}
                handleChange={val => {
                  setEmail(val);
                  setEmailError('');
                }}
                placeholder="Enter email"
              />
            </div>
          )}
        </Styled.InputWrapper>
        <div className="role-select">
          <Label text={t('Role')} inputId="role" />
          <Selector
            options={roleOptions}
            values={selectedRole}
            onChange={val => setSelectedRole(val)}
          />
        </div>
      </div>
    </Modal>
  );
};

InviteUserForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default InviteUserForm;
