import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';
import { InputErrorMessage } from 'shared/components';
import * as Styled from './styles';

const Selector = ({
  disabled,
  error,
  options,
  onChange,
  placeholder,
  searchable,
  values,
  ...props
}) => (
  <Styled.Selector className="Selector">
    <Select
      className="Select"
      options={options}
      onChange={onChange}
      values={values}
      disabled={disabled}
      searchable={searchable}
      dropdownGap={0}
      placeholder={placeholder}
      {...props}
    />
    {error && <InputErrorMessage text={error} />}
  </Styled.Selector>
);

Selector.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  options: PropTypes.array,
  values: PropTypes.array,
};

Selector.defaultProps = {
  disabled: false,
  error: '',
  onChange: () => {},
  placeholder: '',
  searchable: false,
  options: [],
  values: [],
};

export default Selector;
