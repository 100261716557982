import PropTypes from 'prop-types';
import * as Styled from './styles';

const CardRow = ({
  label,
  value,
}) => (
  <Styled.Row>
    <Styled.Label>{label}</Styled.Label>
    <Styled.Value>{value || '-'}</Styled.Value>
  </Styled.Row>
);

CardRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

CardRow.defaultProps = {
  value: '-',
};

export default CardRow;
