import { Route, Switch } from 'react-router-dom';
import HomeContainer from 'HomeContainer';
import Settings from 'Settings';
import Profile from 'Profile';
import Users from 'Users';
import UserDetails from 'UserDetails';
import Header from 'Header';

const AppContainer = () => (
  <div className="AppContainer">
    <Header />
    <Switch>
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/users" component={Users} />
      <Route path="/users/:userID" component={UserDetails} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/" component={HomeContainer} />
    </Switch>
  </div>
);

export default AppContainer;
