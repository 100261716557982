import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Button, ContentLoader } from 'shared/components';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '100px 0',
  },
  content: {
    position: 'relative',
    inset: 'initial',
    bored: 'none',
    backgroundColor: '#fff',
    borderRadius: '3px',
    outline: 'none',
    padding: '0',
  },
};

const Modal = ({
  children,
  closeCb,
  confirmCb,
  disabled,
  isOpen,
  title,
  size,
  ...rest
}) => {
  const [t] = useTranslation();

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {
        if (!disabled) {
          closeCb();
        }
      }}
      closeTimeoutMS={150}
      {...rest}
    >
      <Styled.Modal size={size}>
        {disabled && <Styled.DisabledOverlay><ContentLoader text="" /></Styled.DisabledOverlay>}
        <Styled.ModalHeader>{title}</Styled.ModalHeader>
        <Styled.ModalBody>{children}</Styled.ModalBody>
        <Styled.ModalFooter>
          <Button
            onClick={closeCb}
            variation="link"
          >
            {t('Cancel')}
          </Button>
          <Button
            variation="success"
            onClick={confirmCb}
          >
            {t('Confirm')}
          </Button>
        </Styled.ModalFooter>
        <Styled.ModalDismiss type="button" onClick={closeCb}>
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.07107 8.48528L1.41421 14.1421L0 12.7279L5.65685 7.07107L0 1.41421L1.41421 0L7.07107 5.65685L12.7279 0L14.1421 1.41421L8.48528 7.07107L14.1421 12.7279L12.7279 14.1421L7.07107 8.48528Z" fill="#3B414B" />
          </svg>
        </Styled.ModalDismiss>
      </Styled.Modal>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Modal.defaultProps = {
  isOpen: false,
  disabled: false,
  size: 'md',
  title: '',
};

export default Modal;
