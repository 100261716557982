import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.dimensions.headerHeight};
  background-color: ${props => props.theme.colors.headerBackground};
  color: #fff;
  width: 100%;
  position: relative;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
`;

export const HeaderPageTitle = styled.div`
  margin-left: 20px;
  font-weight: 600;
  font-size: 18px;
`;

export const HeaderRight = styled.div`
  padding-right: 24px;
`;
