import styled from 'styled-components';

export const BactBtn = styled.div`
  margin-bottom: 20px;

  a {
    font-size: 13px;
    padding: 10px 10px 10px 0;
    color: ${props => props.theme.colors.blue}
  }
`;
