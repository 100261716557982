import PropTypes from 'prop-types';
import * as Styled from './styles';

const Label = ({
  inputId,
  text,
  upperCase,
}) => (
  <Styled.Label upperCase={upperCase}>
    <Styled.LabelElem htmlFor={inputId}>{text}</Styled.LabelElem>
  </Styled.Label>
);

Label.propTypes = {
  inputId: PropTypes.string,
  text: PropTypes.string,
  upperCase: PropTypes.bool,
};

Label.defaultProps = {
  inputId: 'placeholderID',
  text: 'Label',
  upperCase: true,
};

export default Label;
