/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import api from 'shared/api';
import { appName } from 'shared/constants';
import * as actionTypes from './actionTypes';

// user actions
const initGetUser = (actions) => () => ({
  type: actions.getUserInit,
});

const getUserError = (actions) => () => ({
  type: actions.getUserError,
});

const getUserSuccess = (actions) => (res) => {
  const userAppName = get(res, 'app_name');
  if (userAppName !== appName) {
    // logout this user
    api.delete('/api/user-management/auth');
    return ({
      type: actionTypes.logoutSuccess,
      user: null,
    });
  }

  return ({
    type: actions.getUserSuccess,
    user: res,
  });
};

export const getUser = () => (dispatch) => {
  dispatch(initGetUser(actionTypes)());
  return api.get('/api/user-management/auth').then(
    (res) => dispatch(getUserSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getUserError(actionTypes)()),
  );
};

export const refreshUser = (res) => ({
  type: actionTypes.refreshUser,
  user: res,
});

// login actions
const initLogin = (actions) => () => ({
  type: actions.loginInit,
});

export const loginSuccess = (res) => (dispatch) => dispatch({
  type: actionTypes.loginSuccess,
  user: res,
});

export const loginError = (res) => (dispatch) => dispatch({
  type: actionTypes.loginError,
  payload: res,
});

export const login = (loginData) => (dispatch) => {
  dispatch(initLogin(actionTypes)());
  return api.post('/api/user-management/auth', loginData);
};

// logout actions
export const logoutSuccess = () => (dispatch) => dispatch({
  type: actionTypes.logoutSuccess,
  user: null,
});

export const logoutError = () => (dispatch) => dispatch({
  type: actionTypes.logoutError,
});

export const logout = () => (dispatch) => {
  dispatch({ type: actionTypes.logoutInit });
  return api.delete('/api/user-management/auth');
};

// register actions
const initRegister = actions => () => ({
  type: actions.registerInit,
});

export const registerSuccess = res => dispatch => dispatch({
  type: actionTypes.registerSuccess,
  user: res,
});

export const registerError = res => dispatch => dispatch({
  type: actionTypes.registerError,
  payload: res,
});

export const register = registerData => (dispatch) => {
  dispatch(initRegister(actionTypes)());
  return api.post('/api/user-management/register', registerData);
};
