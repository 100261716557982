import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  PasswordInput,
} from 'shared/components';
import {
  login,
  loginError,
  loginSuccess,
} from 'redux/account/actions';
import * as Styled from './styles';

const LoginContainer = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => get(state, 'account.checkingUser'));
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
  }, [currentUserID, history]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const loginData = {
      user_name: username,
      password,
      app_name: 'admin',
    };

    dispatch(login(loginData))
      .then((res) => {
        const userData = get(res, 'data');
        dispatch(loginSuccess(userData));
        history.push('/');
      })
      .catch((err) => {
        dispatch(loginError(err));
        toast.error('You provided wrong credentials');
      });
    return true;
  };

  return (
    <Styled.LoginContainer>
      <Styled.FormContainer>
        <form
          className="login-form"
          id="login-form"
          onSubmit={handleFormSubmit}
        >
          <fieldset disabled={isLoading}>
            <div className="login-view">
              <Styled.FormHeading>
                <h1>{t('Login')}</h1>
              </Styled.FormHeading>
              <Styled.FormRow>
                <TextInput
                  disabled={isLoading}
                  handleChange={val => setUsername(val)}
                  id="login-username"
                  value={username}
                  placeholder={t('Username')}
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <PasswordInput
                  disabled={isLoading}
                  handleChange={val => setPassword(val)}
                  id="login-pass"
                  value={password}
                  placeholder={t('Password')}
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <Button
                  disabled={isLoading}
                  variation="success"
                  type="submit"
                  size="lg"
                  onClick={handleFormSubmit}
                >
                  {t('Login')}
                </Button>
              </Styled.FormRow>
            </div>
          </fieldset>
        </form>
      </Styled.FormContainer>
    </Styled.LoginContainer>
  );
};

export default LoginContainer;
