import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import api from 'shared/api';
import {
  ContentLoader,
  Page,
} from 'shared/components';
import { UserCard, UserFiles } from './components';
import * as Styled from './styles';

const UserDetails = () => {
  const params = useParams();
  const { userID } = params;
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    api.get(`/api/user-management/user-profiles/${userID}`)
      .then((res) => {
        setUser(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [userID]);

  if (isLoading) {
    return <Page><ContentLoader /></Page>;
  }

  if (!user) {
    return <Page><div>No data</div></Page>;
  }

  return (
    <Page>
      <div className="UserDetails">
        <Styled.BactBtn>
          <Link to="/users">Back</Link>
        </Styled.BactBtn>
        <UserCard user={get(user, 'user')} />
        <UserFiles user={get(user, 'user')} />
      </div>
    </Page>
  );
};

export default UserDetails;
