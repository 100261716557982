import { useState } from 'react';
import {
  Button, Page,
} from 'shared/components';
import InviteUserForm from './components/InviteUserForm';
import AddAdminUserForm from './components/AddAdminUserForm';
import * as Styled from './styles';

const HomeContainer = () => {
  const [isInvitationFormDisplayed, setInvitationFormDisplayed] = useState(false);
  const [isRegisterFormDisplayed, setRegisterFormDisplayed] = useState(false);

  return (
    <Page>
      <div className="HomeContainer">
        <Styled.HomeContainerActions>
          <Button
            size="lg"
            onClick={() => setInvitationFormDisplayed(true)}
          >
            Send client invitation
          </Button>
          <Button
            size="lg"
            variation="success"
            onClick={() => setRegisterFormDisplayed(true)}
          >
            Add admin user
          </Button>
        </Styled.HomeContainerActions>
        {isInvitationFormDisplayed && (
          <InviteUserForm
            isOpen={isInvitationFormDisplayed}
            closeCb={() => setInvitationFormDisplayed(false)}
          />
        )}
        {isRegisterFormDisplayed && (
          <AddAdminUserForm
            isOpen={isRegisterFormDisplayed}
            closeCb={() => setRegisterFormDisplayed(false)}
          />
        )}
      </div>
    </Page>
  );
};

export default HomeContainer;
