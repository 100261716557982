import * as Styled from './styles';

const Spinner = () => (
  <Styled.Spinner className="Spinner">
    <Styled.Bounce className="bounce1" />
    <Styled.Bounce className="bounce2" />
    <Styled.Bounce className="bounce3" />
  </Styled.Spinner>
);

export default Spinner;
