import { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarMenu from 'SidebarMenu';
import * as Styled from './styles';

const Header = () => {
  const location = useLocation();
  const MenuContext = createContext();

  const [menuOpenState, setMenuOpenState] = useState(false);

  const getTitle = () => {
    const { pathname } = location;

    const isUsersPath = pathname.includes('/users/');
    const isUserDetails = isUsersPath && pathname.split('/').length > 2;

    if (isUserDetails) {
      return 'User details';
    }

    switch (pathname) {
      case '/':
        return 'Dashboard';
      case '/users':
        return 'Users';
      case '/settings':
        return 'Settings';
      case '/profile':
        return 'Profile';
      default:
        return '';
    }
  };

  const contextValue = {
    isMenuOpen: menuOpenState,
    toggleMenu: () => setMenuOpenState(!menuOpenState),
    stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
  };

  return (
    <MenuContext.Provider value={contextValue}>
      <Styled.Header>
        <Styled.HeaderLeft>
          <SidebarMenu context={MenuContext} />
          <Styled.HeaderPageTitle>{getTitle()}</Styled.HeaderPageTitle>
        </Styled.HeaderLeft>
        <Styled.HeaderRight />
      </Styled.Header>
    </MenuContext.Provider>
  );
};

export default Header;
