import PropTypes from 'prop-types';
import * as Styled from './styles';

const InputErrorMessage = ({ text }) => (text ? <Styled.ErrorMessage>{text}</Styled.ErrorMessage> : null);

InputErrorMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

InputErrorMessage.defaultProps = {
  text: '',
};

export default InputErrorMessage;
