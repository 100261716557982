import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Label,
  Modal,
  PasswordInput,
  Selector,
  TextInput,
} from 'shared/components';
import { emailRegex } from 'shared/constants';

const roleOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Super-user',
    value: 'super_user',
  },
  {
    label: 'Reporter',
    value: 'reporter',
  },
];

const AddAdminUserForm = ({
  isOpen,
  closeCb,
}) => {
  const [t] = useTranslation();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState([roleOptions[0]]);

  const isEmailValid = () => {
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setEmailError('Enter valid email');
    }
    return isValid;
  };

  const isPasswordValid = () => {
    if (!password) {
      setPasswordError('This field is required');
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }

    const isValidEmail = isEmailValid();
    const isValidPassword = isPasswordValid();
    if (!isValidEmail || !isValidPassword) {
      toast.error('Enter valid data');
      return false;
    }

    setLoading(true);

    const data = {
      user_name: email,
      password: 'test1234',
      role_name: get(selectedRole, '[0].value') || 'admin',
      app_name: 'admin',
    };

    api.post('/api/user-management/register', data)
      .then(() => {
        toast.success('User registered');
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title="Add admin user"
      size="sm"
    >
      <div className="AddAdminUserForm">
        <div className="input-wrapper">
          <TextInput
            name="email"
            id="email"
            type="email"
            autoComplete
            error={emailError}
            handleChange={val => setEmail(val)}
            placeholder="Enter email"
          />
        </div>
        <div className="input-wrapper">
          <PasswordInput
            disabled={isLoading}
            handleChange={val => setPassword(val)}
            id="register-pass"
            value={password}
            error={passwordError}
            placeholder={t('Password')}
          />
        </div>
        <div className="role-select">
          <Label text={t('Role')} inputId="role" />
          <Selector
            options={roleOptions}
            values={selectedRole}
            onChange={val => setSelectedRole(val)}
          />
        </div>
      </div>
    </Modal>
  );
};

AddAdminUserForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default AddAdminUserForm;
