import styled, { keyframes } from 'styled-components';

const fadeInPage = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const Page = styled.div`
  height: calc(100vh - 50px);
  padding: 12px 10px;
  width: 100%;
  overflow-y: auto;
  position: relative;
`;

export const PageContent = styled.div`
  animation-delay: 0;
  animation-duration: 100ms;
  animation-name: ${fadeInPage};
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
`;

export const PageTitle = styled.div`
  margin: 10px 0 30px;
  font-size: 18px;
  font-weight: 600;
`;
