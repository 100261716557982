import styled from 'styled-components';

export const TextInput = styled.div`
  position: relative;
  max-width: ${props => props.theme.form.inputMaxWidth};
  margin-bottom: 10px;

  .Spinner {
    position: absolute;
    right: 31px;
    top: 10px;
  }
`;

export const Input = styled.input`
  height: ${props => props.theme.form.inputHeight};
  width: 100%;
  max-width: ${props => props.theme.form.inputMaxWidth};
  min-width: ${props => props.theme.form.inputMaxWidth};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.hasError ? props.theme.colors.error : props.theme.form.inputBorder)};
  outline: none;
  padding: 0 10px;
  color: ${props => props.theme.colors.textPrimary};
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  border-radius: ${props => props.theme.borderRadius};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    min-width: 200px;
  }

  &:disabled {
    background-color: ${props => props.theme.form.inputDisabledBackground};

    &:hover {
      border-color: ${props => props.theme.form.inputDisabledBorder};
    }
  }

  &:focus,
  &:hover {
    border-color: ${props => props.theme.form.inputBorderFocus};
  }
`;
