import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import api from 'shared/api';
import {
  ContentLoader,
  Page,
} from 'shared/components';
import * as Styled from './styles';

const Users = () => {
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    api.get('/api/user-management/user-profiles')
      .then((res) => {
        setUsers(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Page><ContentLoader /></Page>;
  }

  if (!users) {
    return <Page><div>No data</div></Page>;
  }

  return (
    <Page>
      <div className="Users">
        <Styled.UsersList>
          {users.map(u => (
            <Styled.ListItem key={get(u, 'profile.user_id')}>
              <Styled.ListItemData>
                <Styled.ListItemUsername>
                  <Styled.ListItemLabel>Username</Styled.ListItemLabel>
                  <Styled.ListItemValue>{get(u, 'user_name')}</Styled.ListItemValue>
                </Styled.ListItemUsername>
                <div className="status">
                  <Styled.ListItemLabel>Is validated</Styled.ListItemLabel>
                  <Styled.ListItemValue>{get(u, 'status')}</Styled.ListItemValue>
                </div>
              </Styled.ListItemData>
              <Styled.ListItemAction>
                <Link to={`/users/${get(u, 'profile.user_id')}`}>Details</Link>
              </Styled.ListItemAction>
            </Styled.ListItem>
          ))}
        </Styled.UsersList>
      </div>
    </Page>
  );
};

export default Users;
