import styled, { css } from 'styled-components';

const getSM = () => css`
  width: 30vw;
  max-width: 448px;
`;

const getMD = () => css`
  width: 40vw;
  max-width: 640px;
`;

const getLG = () => css`
  width: 65vw;
  max-width: 896px;
`;

const getXL = () => css`
  width: 90vw;
  max-width: 1280px;
`;

const sizeStyling = (props) => {
  const { size } = props;

  switch (size) {
    case 'sm':
      return getSM();
    case 'md':
      return getMD();
    case 'lg':
      return getLG();
    case 'xl':
      return getXL();
    default:
      return getMD();
  }
};

export const Modal = styled.div`
  position: relative;
  background-color: #fff;
  box-shadow: 0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.15);
  outline: none;
  color: ${props => props.theme.colors.textPrimary};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: none;
  min-width: 500px;
  ${props => sizeStyling(props)}

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100vw;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    min-width: unset;
  }
`;

export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const ModalHeader = styled.div`
  padding: 20px 40px 20px 20px;
  font-size: 18px;
  font-weight: 500;
`;

export const ModalBody = styled.div`
  padding: 0 20px 30px;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px 15px;

  button {
    margin-left: 24px;
  }
`;

export const ModalDismiss = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  outline: none;

  &:active {
    outline: 2px dashed #CDD2D7;
    outline-offset: 2px;
  }
`;
