import PropTypes from 'prop-types';
import { InputErrorMessage, Spinner } from 'shared/components';
import * as Styled from './styles';

const TextInput = ({
  autoComplete,
  disabled,
  error,
  handleChange,
  loading,
  showErrorMsg,
  ...rest
}) => (
  <Styled.TextInput className="TextInput">
    <Styled.Input
      hasError={!!error}
      type="text"
      disabled={disabled}
      onChange={e => handleChange(e.target.value, e)}
      autoComplete={autoComplete ? 'on' : 'off'}
      {...rest}
    />
    {loading && <Spinner />}
    {showErrorMsg && <InputErrorMessage text={error} /> }
  </Styled.TextInput>
);

TextInput.propTypes = {
  autoComplete: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
};

TextInput.defaultProps = {
  autoComplete: true,
  disabled: false,
  error: '',
  loading: false,
  showErrorMsg: true,
};

export default TextInput;
