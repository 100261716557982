import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  Page,
} from 'shared/components';
import {
  logout,
  logoutError,
  logoutSuccess,
} from 'redux/account/actions';
import * as Styled from './styles';

const Settings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        history.push('/login');
      })
      .catch((err) => {
        dispatch(logoutError(err));
        toast.error('Unable to logout');
      });
  };

  return (
    <Page>
      <div className="Settings">
        <Styled.Logout>
          <Button
            wide
            variation="error"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Styled.Logout>
      </div>
    </Page>
  );
};
export default Settings;
