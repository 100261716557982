import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import api from 'shared/api';
import { ContentLoader } from 'shared/components';
import UserImage from '../UserImage';
import * as Styled from './styles';

const UserFiles = ({
  user,
}) => {
  const userID = get(user, 'profile.user_id');

  const [userFiles, setUserFiles] = useState([]);
  const [selfieImages, setSelfieImages] = useState(null);
  const [idImages, setIdImages] = useState(null);
  const [imagesLoading, setImagesLoading] = useState(true);

  const getSelfies = async (files) => {
    const selfieTypeImages = files.filter(f => get(f, 'type_name') === 'selfie');
    const sortedSelfies = sortBy(selfieTypeImages, (f) => new Date(get(f, 'file.created_at'))).reverse();

    const selfies = await Promise.all(sortedSelfies.map(async (i) => {
      let image;
      await api.get(
        `/api/user-management/files/${get(i, 'file.id')}/content`,
        { responseType: 'blob' },
      ).then((res) => {
        image = {
          ...i,
          img: URL.createObjectURL(get(res, 'data')),
        };
      });
      return image;
    }));

    return selfies;
  };

  const getIdImages = async (files) => {
    const idTypeImages = files.filter(f => get(f, 'type_name') === 'id_card');
    const sortedIds = sortBy(idTypeImages, (f) => new Date(get(f, 'file.created_at'))).reverse();

    const ids = await Promise.all(sortedIds.map(async (i) => {
      let image;
      await api.get(
        `/api/user-management/files/${get(i, 'file.id')}/content`,
        { responseType: 'blob' },
      ).then((res) => {
        image = {
          ...i,
          img: URL.createObjectURL(get(res, 'data')),
        };
      });
      return image;
    }));

    return ids;
  };

  const handleImages = async (files) => {
    setUserFiles(files);
    const selfies = await getSelfies(files);
    const ids = await getIdImages(files);

    setSelfieImages(selfies);
    setIdImages(ids);
    setImagesLoading(false);
  };

  useEffect(() => {
    api.get(`/api/user-management/users/${userID}/files`)
      .then((res) => {
        handleImages(get(res, 'data'));
      })
      .catch(() => {
        setImagesLoading(false);
      });
  }, [userID]);

  if (imagesLoading) {
    return (
      <Styled.UserFiles>
        <ContentLoader text="Getting user files" />
      </Styled.UserFiles>
    );
  }

  if (!userFiles.length) {
    return (
      <Styled.UserFiles>
        <Styled.UserFilesEmpty>No files</Styled.UserFilesEmpty>
      </Styled.UserFiles>
    );
  }

  return (
    <Styled.UserFiles>
      <Styled.UserFilesHeading>Files</Styled.UserFilesHeading>
      <div className="data">
        <div className="selfie">
          <Styled.UserFilesLabel>Selfie</Styled.UserFilesLabel>
          <Styled.UserFilesValue>
            {selfieImages.map((i => <UserImage image={i} key={get(i, 'file.id')} />))}
          </Styled.UserFilesValue>
        </div>
        <div className="id-card">
          <Styled.UserFilesLabel>ID card</Styled.UserFilesLabel>
          <Styled.UserFilesValue>
            {idImages.map((i => <UserImage isID image={i} key={get(i, 'file.id')} />))}
          </Styled.UserFilesValue>
        </div>
      </div>
    </Styled.UserFiles>
  );
};

UserFiles.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserFiles;
