import PropTypes from 'prop-types';
import * as Styled from './styles';

const Page = ({
  children,
  title,
}) => (
  <Styled.Page>
    <Styled.PageContent>
      {title && <Styled.PageTitle>{title}</Styled.PageTitle>}
      <div className="Page-body">
        {children}
      </div>
    </Styled.PageContent>
  </Styled.Page>
);

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Page.defaultProps = {
  title: null,
};

export default Page;
