import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatDate } from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import * as Styled from './styles';

const UserImage = ({
  image,
  isID,
}) => (
  <Styled.ImageWrapper>
    <Styled.Image key={image.file.id} src={image.img} alt="" isID={isID} />
    <Styled.ImageDate>{formatDate(get(image, 'file.created_at'), defaultDateTimeFormat)}</Styled.ImageDate>
  </Styled.ImageWrapper>
);

UserImage.propTypes = {
  image: PropTypes.object.isRequired,
  isID: PropTypes.bool,
};

UserImage.defaultProps = {
  isID: false,
};

export default UserImage;
