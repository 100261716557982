import { isValid, format } from 'date-fns';
import { defaultDateFormat } from 'shared/constants';

export const formatDate = (val, formatType = defaultDateFormat, fallback = '-') => {
  const date = new Date(val);
  const isDateValid = isValid(date);

  if (!val || !isDateValid) {
    return fallback;
  }
  return format(date, formatType);
};

export const checkKycStatus = (status) => {
  const value = status ? status.toLowerCase() : 'pending';
  switch (value) {
    case 'pending':
      return ({
        isValidated: false,
        status: value,
      });
    case 'failed':
      return ({
        isValidated: false,
        status: value,
      });
    case 'validated':
      return ({
        isValidated: true,
        status: value,
      });
    default:
      return ({
        isValidated: false,
        status: '',
      });
  }
};
