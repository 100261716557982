import styled from 'styled-components';

export const HomeContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding-top: 20px;

  button {
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding-top: 40px;

    button {
      margin-bottom: 30px;
    }
  }
`;
