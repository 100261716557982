import styled from 'styled-components';

export const ProfileHeading = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  background-color: white;
`;

export const ImageLoading = styled.div`

  .ContentLoader {
    padding: 0;
  }
`;

export const Image = styled.div`

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const Username = styled.div`
  font-size: 18px;
  font-weight: 600;
  word-break: break-all;
`;
