import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import api from 'shared/api';
import {
  Modal,
  PasswordInput,
} from 'shared/components';
import * as Styled from './styles';

const ChangePasswordForm = ({
  isOpen,
  closeCb,
  userID,
}) => {
  const [t] = useTranslation();

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const isDataValid = () => {
    let validOldPassword = true;
    let validNewPassword = true;
    if (!oldPassword) {
      setOldPasswordError('This field is required');
      validOldPassword = false;
    }
    if (!newPassword) {
      setNewPasswordError('This field is required');
      validNewPassword = false;
    }
    return validOldPassword && validNewPassword;
  };

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }

    const isValid = isDataValid();
    if (!isValid) {
      toast.error('Enter valid data');
      return false;
    }

    setLoading(true);

    const data = {
      user_id: userID,
      old_password: oldPassword,
      new_password: newPassword,
    };

    api.post('/api/user-management/change-password', data)
      .then(() => {
        toast.success('Password changed succesfully');
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      closeCb={closeCb}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title="Change password"
      size="sm"
    >
      <div className="ChangePasswordForm">
        <Styled.InputWrapper>
          <PasswordInput
            disabled={isLoading}
            handleChange={val => {
              setOldPassword(val);
              setOldPasswordError('');
            }}
            id="old-pass"
            value={oldPassword}
            error={oldPasswordError}
            placeholder={t('Old password')}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <PasswordInput
            disabled={isLoading}
            handleChange={val => {
              setNewPassword(val);
              setNewPasswordError('');
            }}
            id="new-pass"
            value={newPassword}
            error={newPasswordError}
            placeholder={t('New password')}
          />
        </Styled.InputWrapper>
      </div>
    </Modal>
  );
};

ChangePasswordForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  userID: PropTypes.number.isRequired,
};

export default ChangePasswordForm;
